


















import Vue from 'vue'
import { Swiper, Autoplay } from 'swiper'
import lang from '@/mixin/lang'
import 'swiper/swiper-bundle.css'
Swiper.use([Autoplay])
export default Vue.extend({
  mixins: [lang],
  computed: {
    lists() {
      return [
        {
          id: 1,
          title: this.$t('lang.SC1'),
          text: this.$t('lang.SC1T')
        },
        {
          id: 2,
          title: this.$t('lang.SC2'),
          text: this.$t('lang.SC2T')
        },
        {
          id: 3,
          title: this.$t('lang.SC3'),
          text: this.$t('lang.SC3T')
        },
        {
          id: 4,
          title: this.$t('lang.SC4'),
          text: this.$t('lang.SC4T')
        },
        {
          id: 5,
          title: this.$t('lang.SC5'),
          text: this.$t('lang.SC5T')
        },
        {
          id: 6,
          title: this.$t('lang.SC6'),
          text: this.$t('lang.SC6T')
        },
        {
          id: 7,
          title: this.$t('lang.SC7'),
          text: this.$t('lang.SC7T')
        },
        {
          id: 8,
          title: this.$t('lang.SC8'),
          text: this.$t('lang.SC8T')
        },
        {
          id: 9,
          title: this.$t('lang.SC9'),
          text: this.$t('lang.SC9T')
        },
      ]
    } 
  },
  data() {
    return {
      swiper2: {}
    }
  },
  mounted() {
    console.log(this.lists)
    setTimeout(() => {
    // this.$nextTick(() => {
      this.swiper2 = new Swiper('.swiper-scene', {
        slidesPerView : 1.5,
        centeredSlides : true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        speed: 500,
        observer: true,
        observeSlideChildren: true,
        // virtual: true,
      })
    }, 200)
  },
  beforeDestroy() {
    this.swiper2 = {}
  },
  watch: {
    $route(n: any){
      if (n.path !== '/home') return
      const _t = this.swiper2 as any
      _t.destroy()
      this.$nextTick(() => {
        setTimeout(() => {
          this.swiper2 = new Swiper('.swiper-scene', {
            slidesPerView : 1.5,
            centeredSlides : true,
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },
            observer:true,
            observeSlideChildren:true,
            // virtual: true,
          })
        }, 200)
      })
    }
  }
})
