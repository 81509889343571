

















import Vue from 'vue';
import WxBar from '@/components/mobile/wx-bar.vue'
import MExplain from '@/components/mobile/m-explain.vue'
import MFeature from '@/components/mobile/m-feature.vue'
import MMechanism from '@/components/mobile/m-mechanism.vue'
import MIntegral from '@/components/mobile/m-integral.vue'
import MScene from '@/components/mobile/m-scene.vue'
import MLink from '@/components/mobile/m-link.vue'
import lang from '@/mixin/lang'
export default Vue.extend({
  name: 'Home',
  mixins: [lang],
  components: {
    WxBar,
    MExplain,
    MFeature,
    MMechanism,
    MIntegral,
    MScene,
    MLink
  },
  data() {
    return {
      showWx: true
    }
  },
  methods: {
    setShow(v: boolean) {
      this.showWx = v
    }
  }
});
