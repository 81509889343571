






















import Vue from "vue";
import lang from "@/mixin/lang";
export default Vue.extend({
  mixins: [lang],
  data() {
    return {
      timer: null,
      currentTop: 0,
      clientWidth: 0,
      clientHeight: 0,
      itemWidth: 45,
      itemHeight: 40,
      bottom: "50%",
    };
  },
  // computed: {
  //   itemWidth() {
  //     return this.initShow ? 45 : 95
  //   },
  //   itemHeight() {
  //     return this.initShow ? 40 : 249
  //   }
  // },
  props: {
    initShow: Boolean,
    gapWidth: {
      type: Number,
      default: 10,
    },
    coefficientHeight: {
      type: Number,
      default: 0.8,
    },
  },
  methods: {
    handleChange() {
      this.$emit("changeShow", !this.initShow);
    },
  },
  watch: {
    initShow(n) {
      if (n) {
        this.itemWidth = 45;
        this.itemHeight = 40;
      } else {
        this.itemWidth = 95;
        this.itemHeight = 249;
        if (parseFloat(this.bottom) > this.clientHeight - this.itemHeight) {
          this.bottom = this.clientHeight - this.itemHeight + "px";
        }
      }
    },
  },
  mounted() {
    this.clientWidth = window.innerWidth;
    this.clientHeight = window.innerHeight;
    window.onresize = () => {
      this.clientWidth = window.innerWidth;
      this.clientHeight = window.innerHeight;
    };
    window.onscroll = () => {
      this.clientWidth = window.innerWidth;
      this.clientHeight = window.innerHeight;
    };
    this.$nextTick(() => {
      const div = this.$refs.div as any;
      div.addEventListener("touchstart", (e: any) => {
        e.stopPropagation();
        div.style.transition = "none";
      });
      div.addEventListener(
        "touchmove",
        (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.targetTouches.length === 1) {
            let touch = e.targetTouches[0];
            this.bottom =
              this.clientHeight - touch.clientY - this.itemHeight / 2 + "px";
            if (parseFloat(this.bottom) <= 0) {
              this.bottom = "0";
            } else if (
              parseFloat(this.bottom) >
              this.clientHeight - this.itemHeight
            ) {
              this.bottom = this.clientHeight - this.itemHeight + "px";
            }
          }
        },
        { passive: false }
      );
      div.addEventListener("touchend", (e: any) => {
        e.stopPropagation();
        div.style.transition = "all 0.3s";
        if (parseFloat(this.bottom) <= 0) {
          this.bottom = "0";
        } else if (parseFloat(this.bottom) > this.clientHeight) {
          this.bottom = this.clientHeight - this.itemHeight + "px";
        }
      });
    });
  },
});
