








































































import Vue from 'vue'
import {Swiper, Autoplay, Pagination} from 'swiper'
import lang from '@/mixin/lang'
import 'swiper/swiper-bundle.css'
Swiper.use([ Pagination, Autoplay])
export default Vue.extend({
	mixins: [lang],
  data() {
    return {
      swiper1: {}
    }
  },
  mounted() {
    this.swiper1 = new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      // virtual: true,
    })
  },
  watch: {
    $route(n: any) {
      if (n.path !== '/home') return
      const _t = this.swiper1 as any
      _t.destroy()
      this.$nextTick(() => {
        this.swiper1 = new Swiper('.swiper-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          speed: 500,
          // virtual: true,
        })
      })
      
    }
  }
})
