



























import Vue from 'vue'
import MExplainText from '@/components/mobile/m-explainText.vue'
import lang from '@/mixin/lang'
export default Vue.extend({
  mixins: [lang],
  components: {
    MExplainText
  },
  data() {
    return {
      index: 1,
      timer: 0
    }
  },
  methods: {
		handleChange(v: number) {
			this.index = v
			if (this.timer) clearInterval(this.timer)
			this.timer = window.setInterval(() => {
				this.index < 4 ? this.index++ : this.index = 1
			}, 4000)
		}
	},
  created() {
		this.timer = window.setInterval(() => {
			this.index < 4 ? this.index++ : this.index = 1
		}, 4000)
	},
	beforeDestroy() {
		clearInterval(this.timer)
	}
})
