import Vue from 'vue'
export default Vue.extend({
	data() {
    return {
      enStyle: false
    }
  },
  created() {
    this.enStyle = this.$route.query?.lang === 'en'
  },
  watch: {
    $route(n) {
      this.enStyle = n.query?.lang === 'en'
    }
  }
})